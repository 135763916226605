import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class SecurityInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestUpdate: any = {
      withCredentials: true
    };

    /*

    if (this.WRITE_METHODS.includes(request.method)) {
      const token = this.tokenExtractor.getToken();
      if (!token) {
        throw new Error('XSRF token not obtainable');
      }
      requestUpdate.setHeaders = {
        [this.XSRF_TOKEN_HEADER]: token
      };
    }

     */
    return next.handle(request.clone(requestUpdate));
  }
}
