import { Component } from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {AppService} from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private router: Router, private appService: AppService) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.appService.loading.next(true);
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.appService.loading.next(false);
      }
    });
  }
}
